import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { WorkingBox, WrokingHead } from "../../style/Theme-Style";

const DevStagesComponent = () => {
  return (
    <>
      <Box>
        <Container>
          <Grid container>
            <Grid lg={6} mx="auto">
              <WrokingHead>
                <Typography variant="h2">How It Works</Typography>
              </WrokingHead>
            </Grid>
          </Grid>
          <Grid container>
            <Grid lg={3} md={3} sm={6} xs={12}>
              <WorkingBox>
                <Typography variant="h2">01</Typography>
                <Typography variant="h6">Concept</Typography>
                <Typography>
                  Unleash your vision with a well-defined concept, the seed of
                  innovation that sparks creativity and sets the direction for
                  your project.
                </Typography>
              </WorkingBox>
            </Grid>
            <Grid lg={3} md={3} sm={6} xs={12}>
              <WorkingBox>
                <Typography variant="h2">02</Typography>
                <Typography variant="h6">Prototype</Typography>
                <Typography>
                  Transform your concept into a tangible reality through
                  meticulous prototyping, where ideas take shape, and potential
                  challenges are addressed.
                </Typography>
              </WorkingBox>
            </Grid>
            <Grid lg={3} md={3} sm={6} xs={12}>
              <WorkingBox>
                <Typography variant="h2">03</Typography>
                <Typography variant="h6">Production</Typography>
                <Typography>
                  Witness your vision come to life as we navigate the production
                  phase, combining precision and expertise to craft a final
                  product that exceeds expectations.
                </Typography>
              </WorkingBox>
            </Grid>
            <Grid lg={3} md={3} sm={6} xs={12}>
              <WorkingBox>
                <Typography variant="h2">04</Typography>
                <Typography variant="h6">Delivery</Typography>
                <Typography>
                  Experience the culmination of your journey as we deliver the
                  finished product, ensuring a seamless transition from concept
                  to reality, ready to make an impact.
                </Typography>
              </WorkingBox>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default DevStagesComponent;
