import React from "react";
import { Typography } from "@mui/material";
import { BannerCntnt, PrimaryBtn } from "../../style/Theme-Style";
import { Link } from "react-router-dom";
import ArrowRight from "../../assets/images/arrow-right.svg";

const BannerContentSec = (curElem) => {
  const { servicBannerSubtitle, seviceBannerName, serviceBannerDetail } =
    curElem;
  return (
    <BannerCntnt>
      <Typography variant="h6">{servicBannerSubtitle}</Typography>
      <Typography variant="h2">{seviceBannerName}</Typography>
      <Typography>{serviceBannerDetail}</Typography>
      <Link to="/contact">
        <PrimaryBtn>
          Get a Free Quote <img src={ArrowRight} alt="Right Arrow" />
        </PrimaryBtn>
      </Link>
    </BannerCntnt>
  );
};

export default BannerContentSec;
