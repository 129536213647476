import React from "react";
import { Link } from "react-router-dom";
import { Box, Container, Grid, Typography } from "@mui/material";
import { AboutGrid, AboutSecBox, PrimaryBtn } from "../../style/Theme-Style";
import ArrowRight from "../../assets/images/arrow-right.svg";
import AboutImg from "../../assets/images/aboutSec-Img.jpg";

const AboutSecComponent = () => {
  return (
    <>
      <Box>
        <Container>
          <AboutGrid container>
            <Grid lg={6} md={6} sm={5} xs={12}>
              <Box className="aboutImg">
                <img src={AboutImg} alt="" />
              </Box>
            </Grid>
            <Grid lg={6} md={6} sm={7} xs={12}>
              <AboutSecBox>
                <Typography variant="h6">ABOUT COMPANY</Typography>
                <Typography variant="h2">
                  Crafting Digital Brilliance at
                  <Box component="span"> Ravian Services</Box>
                </Typography>
                <Typography>
                  Welcome to Ravian Services, where creativity meets
                  functionality. As a forward-thinking design company, we are
                  passionate about translating ideas into visually captivating
                  realities. With a team of dedicated professionals, we embark
                  on a journey to understand your unique brand identity and
                  objectives, weaving them into every pixel and line. From
                  innovative graphic designs to seamless website development, we
                  take pride in delivering tailored solutions that leave a
                  lasting impact. At Ravian Services, we don't just design; we
                  create experiences that resonate and inspire.
                </Typography>
                <Link to="/about">
                  <PrimaryBtn>
                    Discover More <img src={ArrowRight} alt="Right Arrow" />
                  </PrimaryBtn>
                </Link>
              </AboutSecBox>
            </Grid>
          </AboutGrid>
        </Container>
      </Box>
    </>
  );
};

export default AboutSecComponent;
