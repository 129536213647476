import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import ServiceHeading from "../../components/ServiceCompponent/ServiceHeading";
import Services from "../Services/Services";
import ContactFromComponent from "../../components/ContactFromComponent/ContactFromComponent";
import {
  BannerBox,
  BannerGrid,
  ContactBox,
  ContactSec,
  Faq,
  FaqBox,
  FaqBoxHead,
  ServiceSec,
} from "../../style/Theme-Style";
import FaqComponent from "../../components/FAQComponent/faqComponent";
import FaqData from "../../data/FAQData";
import BannerImg from "../../assets/images/bannerImg.png";
import BannerSlider from "../../components/BannerComponent/sllider";
import FreeQouteComponent from "../../components/QuoteComponent/QouteComponent";
import AboutSecComponent from "../../components/AboutSecComponent/AboutSecComponent";
import TestimonialSlider from "../../components/TestimonialComponent/TestimonialSlider";
import DevStagesComponent from "../../components/DevStageComponent/DevStageComponent";
import ProjectCounterComponent from "../../components/ProjectCounterComponent/ProjectCounterComponent";

const Home = () => {
  return (
    <>
      {/* bannerSec start */}
      <BannerBox>
        <Container>
          <BannerGrid container>
            <Grid lg={6} md={6} sm={7} xs={12}>
              <BannerSlider />
            </Grid>
            {/* <Grid lg={6} md={6} sm={5} xs={12}>
              <Box component="img" src={BannerImg} width="100%"></Box>
            </Grid> */}
          </BannerGrid>
        </Container>
      </BannerBox>
      {/* bannerSec end */}

      {/* aboutSec start */}
      <AboutSecComponent />
      {/* aboutSec end */}

      {/* ServiceSec start */}
      <ServiceSec>
        <Container>
          <ServiceHeading />
          <Services />
        </Container>
      </ServiceSec>
      {/* ServiceSec end */}

      {/* FreeQouteSec satrt */}
      <FreeQouteComponent />
      {/* FreeQouteSec end */}

      {/* <DevStagesComponent /> */}

      {/* TestimonialSec start */}
      {/* <TestimonialSlider /> */}
      {/* TestimonialSec end */}

      {/* faqSec start */}
      <FaqBox>
        <Container>
          <Grid container>
            <Grid lg={6} md={6} sm={12} xs={12}>
              <FaqBoxHead>
                <Typography variant="h6">ASKED AND QUESTIONS</Typography>
                <Typography variant="h2">
                  We Focus On Quality Not Quantity.
                </Typography>
              </FaqBoxHead>
              <Faq>
                {FaqData.map((curElem) => {
                  return (
                    <>
                      <FaqComponent key={curElem} {...curElem} />
                    </>
                  );
                })}
              </Faq>
            </Grid>
            <Grid lg={6}></Grid>
          </Grid>
        </Container>
      </FaqBox>
      {/* faqSec end */}

      {/* projectCounterSec start */}
      {/* <ProjectCounterComponent /> */}
      {/* projectCounterSec end */}

      {/* contactSec start */}
      <Box sx={{ my: "5rem" }}>
        <Container>
          <ContactSec>
            <Grid container>
              <Grid lg={5} md={5}></Grid>
              <Grid lg={7} md={7} xs={12}>
                <ContactBox>
                  <Typography variant="h2">Make An Appointment</Typography>
                  <ContactFromComponent />
                </ContactBox>
              </Grid>
            </Grid>
          </ContactSec>
        </Container>
      </Box>
      {/* contactSec end*/}

      {/* <FooterComponent /> */}
    </>
  );
};
export default Home;
