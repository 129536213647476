import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { ContactBox, ContactPgDetail, theme } from "../../style/Theme-Style";
import ContactFromComponent from "../../components/ContactFromComponent/ContactFromComponent";
import { Link } from "react-router-dom";
import ContactBanner from "../../assets/images/contactBg.jpg";
import LocationIcon from "../../assets/images/mapmarker.svg";
import MailTcon from "../../assets/images/envelope.svg";
import PhoneIcon from "../../assets/images/phone.svg";

const Contact = () => {
  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Box
          component="img"
          src={ContactBanner}
          alt="contact-Banner"
          width="100%"
          height="400px"
          sx={{ objectFit: "cover" }}
        ></Box>
        <Box
          className="bannerContent"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            height: "100%",
            width: "100%",
            background: "rgb(237 243 252 / 50%)",
          }}
        >
          <Container>
            <Typography
              variant="h2"
              sx={{
                color: theme.palette.primary.main,
                fontWeight: "700",
                fontSize: { lg: "44px", sm: "35px", xs: "30px" },
              }}
            >
              Contact Us
            </Typography>
          </Container>
        </Box>
      </Box>

      <Box sx={{ my: "5rem" }}>
        <Container>
          <Grid container>
            <Grid lg={5}>
              <ContactPgDetail>
                <Typography variant="h6">CONTACT DETAILS</Typography>
                <Typography variant="h2">Contact Us</Typography>
                <Typography>
                  Give us a call or drop by anytime, we endeavour to answer all
                  enquiries within 24 hours on business days. We will be happy
                  to answer your questions.
                </Typography>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <img src={LocationIcon} alt="" />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="h6">Our Address:</Typography>
                      <Typography>
                        17 Whitwell Cl, Nottingham NG8 6JS, UK
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <img src={MailTcon} alt="" />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="h6">Our Mailbox:</Typography>
                      <Typography>
                        <Link to="mailto:info@ravianservices.co.uk">
                          info@ravianservices.co.uk
                        </Link>
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <img src={PhoneIcon} alt="" />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="h6">Our Phone:</Typography>
                      <Typography>
                        <Link to="tel:447914764280">+44 7914 764280</Link>
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </ContactPgDetail>
            </Grid>
            <Grid lg={7}>
              <ContactBox>
                <Typography variant="h6">GET IN TOUCH</Typography>
                <Typography variant="h2" sx={{ padding: "0 !important" }}>
                  Ready To Get Started?
                </Typography>
                <Typography>
                  Your email address will not be published. Required fields are
                  marked *
                </Typography>
                <ContactFromComponent />
              </ContactBox>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Contact;
