import React, { useEffect } from "react";
import {
  PrimaryBtn,
  ServicePgBox,
  ServicePgGrid,
  ServicePgHead,
  ServicePgImg,
  theme,
} from "../../style/Theme-Style";
import { Box, Container, Grid, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import ServicesBanner from "../../assets/images/service-Bg.jpg";
import ArrowRight from "../../assets/images/arrow-right.svg";
import GraphicImg from "../../assets/images/graphicdesigning.jpg";
import WebsiteImg from "../../assets/images/websitedevelopment.jpg";
import SeoImg from "../../assets/images/seo.jpg";
import PaidImg from "../../assets/images/paidmarketing.png";

const Service = () => {
  let location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);
  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Box
          component="img"
          src={ServicesBanner}
          alt="contact-Banner"
          width="100%"
          height="400px"
          sx={{ objectFit: "cover" }}
        ></Box>
        <Box
          className="bannerContent"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            height: "100%",
            width: "100%",
            background: "rgb(237 243 252 / 50%)",
          }}
        >
          <Container>
            <Typography
              variant="h2"
              sx={{
                color: theme.palette.primary.main,
                fontWeight: "700",
                fontSize: { lg: "44px", sm: "35px", xs: "30px" },
              }}
            >
              Services
            </Typography>
          </Container>
        </Box>
      </Box>

      <Box>
        <Container>
          <ServicePgHead>
            <Grid container>
              <Grid
                lg={8}
                md={8}
                sm={10}
                sx={{ mx: "auto", textAlign: "center", mb: 5 }}
              >
                <Typography variant="h6">One-Stop-Development</Typography>
                <Typography variant="h2">
                  Custom <Box component="span"> website development </Box>
                  services from professionals you’ll enjoy working with
                </Typography>
                <Typography>
                  As an emerging software agency, we are eager to establish our
                  company by providing first-class digital services. The quality
                  of projects we develop reflects compliance and commitment with
                  our work. In addition, our exceptional services leads to
                  healthy and longer relations with customers.
                </Typography>
              </Grid>
            </Grid>
          </ServicePgHead>
          <ServicePgBox id="graphicDesigning">
            <ServicePgGrid container>
              <Grid lg={6} md={6} sm={6} xs={12}>
                <ServicePgImg>
                  <img src={GraphicImg} alt="" />
                </ServicePgImg>
              </Grid>
              <Grid lg={6} md={6} sm={6} xs={12}>
                <Typography variant="h2">Graphic Designing</Typography>
                <Typography>
                  Graphic designing is the creative process of visually
                  communicating ideas and messages through the use of
                  typography, imagery, color, and layout. It encompasses a wide
                  range of mediums including digital graphics, print materials,
                  logos, branding elements, and multimedia presentations.
                  Graphic designers combine artistic skill with technical
                  proficiency to produce visually appealing and effective
                  designs that resonate with target audiences and fulfill
                  specific communication objectives. From branding identities to
                  marketing materials, graphic design plays a crucial role in
                  shaping the visual identity and perception of businesses,
                  organizations, and individuals.
                </Typography>
                <Link to="/contact">
                  <PrimaryBtn>
                    Get a Quote <img src={ArrowRight} alt="Right Arrow" />
                  </PrimaryBtn>
                </Link>
              </Grid>
            </ServicePgGrid>
          </ServicePgBox>
          <ServicePgBox id="webDevelopment">
            <ServicePgGrid
              container
              sx={{ flexDirection: { sm: "row", xs: "column-reverse" } }}
            >
              <Grid lg={6} md={6} sm={6} xs={12}>
                <Typography variant="h2">Website Development</Typography>
                <Typography>
                  Website development encompasses a diverse array of languages
                  and platforms tailored to various needs. From the versatility
                  of JavaScript for front-end interactivity to the robustness of
                  PHP for dynamic server-side functionality, developers leverage
                  tools like HTML, CSS, Python, and frameworks such as React,
                  Angular, Django, and WordPress to craft engaging and
                  functional websites. Each language and platform offers
                  distinct advantages, allowing developers to choose the best
                  fit for their project requirements and objectives.
                </Typography>
                <Link to="/contact">
                  <PrimaryBtn>
                    Get a Quote <img src={ArrowRight} alt="Right Arrow" />
                  </PrimaryBtn>
                </Link>
              </Grid>
              <Grid lg={6} md={6} sm={6} xs={12}>
                <ServicePgImg sx={{ marginLeft: "2rem" }}>
                  <img src={WebsiteImg} alt="" width="100%" />
                </ServicePgImg>
              </Grid>
            </ServicePgGrid>
          </ServicePgBox>
          <ServicePgBox id="seo">
            <ServicePgGrid container>
              <Grid lg={6} md={6} sm={6} xs={12}>
                <ServicePgImg>
                  <img src={SeoImg} alt="" width="100%" />
                </ServicePgImg>
              </Grid>
              <Grid lg={6} md={6} sm={6} xs={12}>
                <Typography variant="h2">Search Engine Optimization</Typography>
                <Typography>
                  Search Engine Optimization (SEO) is the practice of optimizing
                  a website to improve its visibility and ranking in search
                  engine results pages (SERPs). By utilizing strategies like
                  keyword research, content optimization, and backlink building,
                  SEO aims to increase organic traffic and enhance the website's
                  relevance to search engine algorithms. With ever-evolving
                  algorithms and user behaviors, effective SEO requires
                  continuous monitoring, adaptation, and adherence to best
                  practices to ensure sustained visibility and competitiveness
                  in the online landscape.
                </Typography>
                <Link to="/contact">
                  <PrimaryBtn>
                    Get a Quote <img src={ArrowRight} alt="Right Arrow" />
                  </PrimaryBtn>
                </Link>
              </Grid>
            </ServicePgGrid>
          </ServicePgBox>
          <ServicePgBox id="paidMarketing">
            <ServicePgGrid
              container
              sx={{ flexDirection: { sm: "row", xs: "column-reverse" } }}
            >
              <Grid lg={6} md={6} sm={6} xs={12}>
                <Typography variant="h2">Paid Marketing</Typography>
                <Typography>
                  Paid marketing involves the strategic allocation of funds to
                  promote products or services through various digital
                  advertising channels such as Google Ads, social media
                  platforms like Facebook, Twitter, and LinkedIn, as well as
                  sponsored content placements. By targeting specific
                  demographics, interests, and behaviors, paid marketing aims to
                  maximize visibility, drive traffic, and generate leads or
                  sales. Effective campaigns require meticulous planning,
                  continual optimization, and analysis of metrics to ensure a
                  positive return on investment (ROI) and achieve marketing
                  objectives.
                </Typography>
                <Link to="/contact">
                  <PrimaryBtn>
                    Get a Quote <img src={ArrowRight} alt="Right Arrow" />
                  </PrimaryBtn>
                </Link>
              </Grid>
              <Grid lg={6} md={6} sm={6} xs={12}>
                <ServicePgImg sx={{ marginLeft: "2rem" }}>
                  <img src={PaidImg} alt="" width="100%" />
                </ServicePgImg>
              </Grid>
            </ServicePgGrid>
          </ServicePgBox>
        </Container>
      </Box>
    </>
  );
};

export default Service;
