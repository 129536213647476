import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import SocialIconsComponent from "../SocialIcons/SocialIcons";
import { BottomRowBox, FooterBox } from "../../style/Theme-Style";
import MobileIcon from "../../assets/images/phone.svg";
import EnvelopeIcon from "../../assets/images/envelope.svg";
import LocationIcon from "../../assets/images/mapmarker.svg";
import BottomRowComponent from "../BottomRow/BottomRowComponent";

const FooterComponent = () => {
  return (
    <>
      <FooterBox>
        <Container>
          <Grid container>
            <Grid lg={4} md={3} sm={6} xs={12}>
              <Link></Link>
              <Typography sx={{ mb: 3, textAlign: "justify" }}>
                The Ravian Services LTD specializes in custom website
                development, offering tech-driven digital consulting for
                emerging brands, startups, and leaders to unleash their brand's
                full online potential.
              </Typography>
              <SocialIconsComponent />
            </Grid>
            <Grid lg={2} md={3} sm={6} xs={12}>
              <Typography className="footerHeading">Quick Links</Typography>
              <List>
                <ListItem>
                  <Link to="/">Home</Link>
                </ListItem>
                <ListItem>
                  <Link to="/about">About Us</Link>
                </ListItem>
                <ListItem>
                  <Link to="/service">Services</Link>
                </ListItem>
                <ListItem>
                  <Link to="/contact">Contact Us</Link>
                </ListItem>
              </List>
            </Grid>
            <Grid lg={3} md={3} sm={6} xs={12}>
              <Typography className="footerHeading">Services</Typography>
              <List>
                <ListItem>
                  <Link to="/service#graphicDesigning">Graphic Designing</Link>
                </ListItem>
                <ListItem>
                  <Link to="/service#webDevelopment">
                    Web Design & Development
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/service#seo">Search Engine Optimization</Link>
                </ListItem>
                <ListItem>
                  <Link to="/service#paidMarketing">Paid Marketing</Link>
                </ListItem>
              </List>
            </Grid>
            <Grid lg={3} md={3} sm={6} xs={12}>
              <Typography className="footerHeading">Contact Us</Typography>
              <List>
                <ListItem sx={{ display: "flex" }}>
                  <ListItemIcon>
                    <img src={MobileIcon} alt="" />
                  </ListItemIcon>
                  <ListItemText>
                    <Link to="tel:447914764280">+44 7914 764280</Link>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <img src={EnvelopeIcon} alt="" />
                  </ListItemIcon>
                  <ListItemText>
                    <Link to="info@ravianservices.co.uk">
                      info@ravianservices.co.uk
                    </Link>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <img src={LocationIcon} alt="" />
                  </ListItemIcon>
                  <ListItemText>
                    17 Whitwell Cl, Nottingham NG8 6JS, UK
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
      </FooterBox>
      <BottomRowBox>
        <Container>
          <BottomRowComponent />
        </Container>
      </BottomRowBox>
    </>
  );
};

export default FooterComponent;
