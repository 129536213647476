import React from "react";
import ServiceBoxComponent from "../../components/ServiceCompponent/SevrviceBoxComponent";
import ServiceData from "../../data/ServicesData";
import { Box, Grid } from "@mui/material";
const Services = () => {
  return (
    <>
      <Box>
        <Grid container>
          {ServiceData.map((curElem) => {
            return (
              <>
                <ServiceBoxComponent key={curElem} {...curElem} />
              </>
            );
          })}
        </Grid>
      </Box>
    </>
  );
};

export default Services;
