import React from "react";
import CountUp from "react-countup";
import { Container, Grid, Typography } from "@mui/material";
import { ProjectCounterBox } from "../../style/Theme-Style";

const ProjectCounterComponent = () => {
  return (
    <>
      <Container>
        <ProjectCounterBox>
          <Grid container>
            <Grid lg={3} md={3} sm={6} xs={12}>
              <CountUp
                start={0}
                end={148}
                duration={2.75}
                separator=" "
                // decimals={4}
                // decimal=","
                // prefix="EUR"
                suffix=" +"
              >
                {({ countUpRef, start }) => (
                  <div>
                    <span ref={countUpRef} />
                    <Typography>COMPLETED PROJECT</Typography>
                  </div>
                )}
              </CountUp>
            </Grid>
            <Grid lg={3} md={3} sm={6} xs={12}>
              <CountUp
                start={0}
                end={200}
                duration={2.75}
                separator=" "
                // decimals={4}
                // decimal=","
                // prefix="EUR"
                suffix=" +"
              >
                {({ countUpRef, start }) => (
                  <div>
                    <span ref={countUpRef} />
                    <Typography>HAPPY CLIENTS</Typography>
                  </div>
                )}
              </CountUp>
            </Grid>
            <Grid lg={3} md={3} sm={6} xs={12}>
              <CountUp
                start={0}
                end={10}
                duration={2.75}
                separator=" "
                // decimals={4}
                // decimal=","
                // prefix="EUR"
                suffix=" +"
              >
                {({ countUpRef, start }) => (
                  <div>
                    <span ref={countUpRef} />
                    <Typography>AWARD WINING</Typography>
                  </div>
                )}
              </CountUp>
            </Grid>
            <Grid lg={3} md={3} sm={6} xs={12}>
              <CountUp
                start={0}
                end={100}
                duration={2.75}
                separator=" "
                // decimals={4}
                // decimal=","
                // prefix="EUR"
                suffix=" %"
              >
                {({ countUpRef, start }) => (
                  <div>
                    <span ref={countUpRef} />
                    <Typography>SATISFACTION RATE</Typography>
                  </div>
                )}
              </CountUp>
            </Grid>
          </Grid>
        </ProjectCounterBox>
      </Container>
    </>
  );
};

export default ProjectCounterComponent;
