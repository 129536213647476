import React from "react";
import {
  AboutPgBox,
  AboutPgGrid,
  ContactBox,
  ContactSec,
  theme,
} from "../../style/Theme-Style";
import AboutBanner from "../../assets/images/aboutus-Bg.jpg";
import { Box, Container, Grid, Typography } from "@mui/material";
import BasicTabs from "../../components/AboutPgTabsComponent/AboutPgTabsComponent";
import TestimonialSlider from "../../components/TestimonialComponent/TestimonialSlider";
import ContactFromComponent from "../../components/ContactFromComponent/ContactFromComponent";
import DevStagesComponent from "../../components/DevStageComponent/DevStageComponent";
import AboutImg from "../../assets/images/aboutSec-Img.jpg";

const About = () => {
  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Box
          component="img"
          src={AboutBanner}
          alt="about-Banner"
          width="100%"
          height="400px"
          sx={{ objectFit: "cover" }}
        ></Box>
        <Box
          className="bannerContent"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            height: "100%",
            width: "100%",
            background: "rgb(237 243 252 / 50%)",
          }}
        >
          <Container>
            <Typography
              variant="h2"
              sx={{
                color: theme.palette.primary.main,
                fontWeight: "700",
                fontSize: { lg: "44px", sm: "35px", xs: "30px" },
              }}
            >
              About Us
            </Typography>
          </Container>
        </Box>
      </Box>

      <Box sx={{ my: "5rem" }}>
        <Container>
          <AboutPgGrid container>
            <Grid lg={6} md={6} sm={12} xs={12}>
              <AboutPgBox>
                <Typography variant="h6">About us</Typography>
                <Typography variant="h2">
                  Select the top IT service provider for your needs.
                </Typography>
                <Typography>
                  Ravians Services has undergone optimization to provide your
                  visitors with an unparalleled UX/UI experience, featuring a
                  distinctive design tailored to various services.
                </Typography>
                <BasicTabs />
              </AboutPgBox>
            </Grid>
            <Grid lg={6} md={6} sm={5} xs={12}>
              <Box className="aboutImg" sx={{ ml: "2rem" }}>
                <img src={AboutImg} alt="" />
              </Box>
            </Grid>
          </AboutPgGrid>
        </Container>
      </Box>

      <DevStagesComponent />

      {/* TestimonialSec start */}
      {/* <TestimonialSlider /> */}
      {/* TestimonialSec end */}

      {/* contactSec start */}
      <Box sx={{ my: "5rem" }}>
        <Container>
          <ContactSec>
            <Grid container>
              <Grid lg={5} md={5}></Grid>
              <Grid lg={7} md={7} xs={12}>
                <ContactBox>
                  <Typography variant="h2">Make An Appointment</Typography>
                  <ContactFromComponent />
                </ContactBox>
              </Grid>
            </Grid>
          </ContactSec>
        </Container>
      </Box>
      {/* contactSec end*/}
    </>
  );
};

export default About;
