import React, { useState } from "react";
import { Alert, FormControl, Grid, TextField } from "@mui/material";
import { ContactFormBox, PrimaryBtn } from "../../style/Theme-Style";
import emailjs from "@emailjs/browser";

/* Install Package 
  npm install @emailjs/browser
*/

const ContactFromComponent = () => {
  const [formData, setFormData] = useState({
    user_name: "",
    user_phone: "",
    user_email: "",
    user_comments: "",
  });
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const handleSubmit = (e) => {
    e.preventDefault();
    // emailjs.sendForm('service_id', 'template_id', form_target, 'User_ID')
    if (validateForm()) {
      emailjs
        .sendForm(
          "service_4p7nkf6",
          "template_go4bruj",
          e.target,
          "GlwF6jODhMW3l93Gj"
        )
        .then(
          (result) => {
            console.log("Email Sent!");
            console.log(result.text);

            setFormData({
              user_name: "",
              user_phone: "",
              user_email: "",
              user_comments: "",
            });
            setSubmissionStatus("success");
          },
          (error) => {
            console.log(error.text);
            setSubmissionStatus("error");
          }
        );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    // Basic validation: Check if fields are not empty
    Object.entries(formData).forEach(([key, value]) => {
      if (!value.trim()) {
        errors[key] = "This field is required";
        isValid = false;
      }
    });

    setValidationErrors(errors);
    return isValid;
  };

  return (
    <>
      <ContactFormBox>
        <form onSubmit={handleSubmit}>
          <Grid container item>
            <Grid lg={6} md={6} sm={6} xs={12}>
              <FormControl>
                <TextField
                  name="user_name"
                  label="Full Name"
                  size="small"
                  value={formData.user_name}
                  onChange={handleChange}
                  error={!!validationErrors.user_name}
                  helperText={validationErrors.user_name}
                />
              </FormControl>
            </Grid>
            <Grid lg={6} md={6} sm={6} xs={12}>
              <FormControl>
                <TextField
                  name="user_phone"
                  label="Phone No."
                  size="small"
                  value={formData.user_phone}
                  onChange={handleChange}
                  error={!!validationErrors.user_phone}
                  helperText={validationErrors.user_phone}
                />
              </FormControl>
            </Grid>
            <Grid lg={12} md={12} sm={12} xs={12}>
              <FormControl>
                <TextField
                  name="user_email"
                  label="Email"
                  size="small"
                  value={formData.user_email}
                  onChange={handleChange}
                  error={!!validationErrors.user_email}
                  helperText={validationErrors.user_email}
                />
              </FormControl>
            </Grid>
            <Grid lg={12} md={12} sm={12} xs={12}>
              <FormControl>
                <TextField
                  name="user_comments"
                  id="outlined-multiline-static"
                  label="Message"
                  multiline
                  rows={3}
                  variant="outlined"
                  value={formData.user_comments}
                  onChange={handleChange}
                  error={!!validationErrors.user_comments}
                  helperText={validationErrors.user_comments}
                />
              </FormControl>
            </Grid>
            <Grid lg={12}>
              <PrimaryBtn type="submit" sx={{ mt: 2 }}>
                Submit
              </PrimaryBtn>
            </Grid>
            <Grid lg={12}>
              {submissionStatus === "success" && (
                <Alert severity="success">Form submitted successfully!</Alert>
              )}
              {submissionStatus === "error" && (
                <Alert severity="error">
                  Form submission failed. Please try again.
                </Alert>
              )}
            </Grid>
          </Grid>
        </form>
      </ContactFormBox>
    </>
  );
};

export default ContactFromComponent;
