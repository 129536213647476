import React from "react";
import { ServiceSecHead } from "../../style/Theme-Style";
import { Box, Grid, Typography } from "@mui/material";

const ServiceHeading = () => {
  return (
    <>
      <ServiceSecHead>
        <Grid container>
          <Grid lg={7} sx={{ mx: "auto", textAlign: "center" }}>
            <Typography variant="h6">Our services</Typography>
            <Typography variant="h2">
              Comprehensive Range Of <Box component="span"> Services</Box> We
              Offer
            </Typography>
            <Typography className="SecDet">
              Discover a range of tailored solutions designed to enhance your
              online presence, from cutting-edge software development to
              strategic digital consultancy. Our services empower your brand to
              thrive in the ever-evolving digital landscape.
            </Typography>
          </Grid>
        </Grid>
      </ServiceSecHead>
    </>
  );
};

export default ServiceHeading;
