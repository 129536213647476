import React from "react";
import Slider from "react-slick";
import HomeSliderData from "../../data/HomeSliderData";
import BannerContentSec from "./bannerComponent";
import { BanSlider } from "../../style/Theme-Style";

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  adaptiveHeight: true,
};

const BannerSlider = () => {
  return (
    <>
      <BanSlider>
        <Slider {...settings} className="bannerSlider">
          {HomeSliderData.map((curElem) => {
            return (
              <>
                <BannerContentSec key={curElem} {...curElem} />
              </>
            );
          })}
        </Slider>
      </BanSlider>
    </>
  );
};

export default BannerSlider;
