import React, { useState } from "react";
import {
  Toolbar,
  CssBaseline,
  useTheme,
  useMediaQuery,
  MenuItem,
  Box,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DrawerComponent from "./drawerComponent";
import { Container } from "@mui/material";
import Logo from "../../assets/images/logo.png";
import { NavigationBar, SubMenu } from "../../style/Theme-Style";
import AngleDown from "../../assets/images/angle-down.svg";

function Navbar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <NavigationBar position="fixed">
      <Container>
        <CssBaseline />
        <Toolbar>
          <Link to="/">
            <img src={Logo} alt="" style={{ width: "170px" }} />
          </Link>
          <Box className="nav">
            {isMobile ? (
              <DrawerComponent />
            ) : (
              <>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Link to="/">Home</Link>
                  <Link to="/about">About</Link>
                  <Link
                    onClick={handleMenuOpen}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    Services
                    <img src={AngleDown} alt="" style={{ width: "24px" }} />
                  </Link>
                  <SubMenu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleMenuClose}>
                      <Link to="/service#graphicDesigning">
                        Graphic Designing
                      </Link>
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose}>
                      <Link to="/service#webDevelopment">Web Development</Link>
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose}>
                      <Link to="/service#seo">Search Engine Optimization</Link>
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose}>
                      <Link to="/service#paidMarketing">Paid Marketing</Link>
                    </MenuItem>
                  </SubMenu>
                  <Link to="/contact">Contact Us</Link>
                </Box>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </NavigationBar>
  );
}

export default Navbar;
