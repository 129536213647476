import GraphicIcon from "../assets/images/graphicIcon.svg";
import WebsiteIcon from "../assets/images/WebDevelopment.svg";
import SEOIcon from "../assets/images/seo.svg";
import PaidIcon from "../assets/images/paidmarketing.svg";

const ServiceData = [
  {
    id: "01",
    imgSrc: GraphicIcon,
    serviceName: "Graphic Designing",
    ServiceDet:
      "Transform your ideas into visual masterpieces with our graphic design expertise. We craft compelling visuals that captivate and communicate, ensuring your brand stands out with creativity and impact.",
  },
  {
    id: "02",
    imgSrc: WebsiteIcon,
    serviceName: "Website Development",
    ServiceDet:
      "Empower your online presence with our website development prowess. We create sleek, functional websites that seamlessly align with your brand, delivering a superior user experience for your audience.",
  },
  {
    id: "03",
    imgSrc: SEOIcon,
    serviceName: "Search Engine Optimization",
    ServiceDet:
      "Boost your online visibility and reach the top of search results with our expert Search Engine Optimization (SEO) services. We optimize your digital presence, ensuring your business is easily discovered by your target audience.",
  },
  {
    id: "04",
    imgSrc: PaidIcon,
    serviceName: "Paid Marketing",
    ServiceDet:
      "Supercharge your brand's reach and engagement through our targeted paid marketing strategies. With precision and expertise, we maximize your advertising investments to drive meaningful results and elevate your business in the competitive digital landscape.",
  },
];

export default ServiceData;
