import { AppBar, Box, Button, Drawer, Grid, Menu, Tabs } from "@mui/material";
import { styled, createTheme } from "@mui/material/styles";
import FaqBG from "../assets/images/faqBg.jpg";
import ContactBG from "../assets/images/contactFormImg.jpg";
import BannerBg from "../assets/images/bannerBg.jpg";
// export const theme = createTheme({});

// export const PricingBox = styled(Box)({
//   height: "40px",
//   width: "20px",
// });

export const theme = createTheme({
  palette: {
    background: {
      default: "#edf3fc",
    },
    primary: {
      main: "#0975b8",
      dark: "#000",
      light: "#fff",
      invisible: "transparent",
      gradient:
        "linear-gradient(to right top, #01a1db, #008fc9, #007db7, #006ca4, #005b92);",
    },
    secondary: {
      main: "#0975b8",
    },
  },
  typography: {
    fontFamily: "'Krub', sans-serif",
    p: { fontSize: "16px" },
    a: {
      fontSize: "16px",
      textDecoration: "none !important",
    },
    // h1: {
    //   fontSize: "80px",
    //   fontWeight: 600,
    //   "@media (max-width:600px)": {
    //     fontSize: "30px",
    //   },
    // },
    // h2: {
    //   fontSize: "38px",
    //   fontWeight: 600,
    // },
    // h3: {
    //   fontSize: "28px",
    //   fontWeight: 600,
    //   "@media (max-width:600px)": {
    //     fontSize: "18px",
    //   },
    // },
    // h4: {
    //   fontSize: "24px",
    //   fontWeight: 600,
    //   "@media (max-width:600px)": {
    //     fontSize: "18px",
    //   },
    // },
    // h5: {
    //   fontSize: "22px",
    //   fontWeight: 600,
    //   "@media (max-width:600px)": {
    //     fontSize: "18px",
    //   },
    // },
    // h6: {
    //   fontSize: "18px",
    //   fontWeight: 600,
    // },
  },
});

// ---------------------------------

// button start
export const StyledBtn = styled(Button)({
  borderRadius: 5,
  fontSize: 16,
  padding: "0.4rem 1rem",
  minWidth: "120px",
  color: theme.palette.primary.dark,
  transition: "0.5s all",
  border: "1px solid transparent",
});
// button end

// PrimaryBtn start
export const PrimaryBtn = styled(StyledBtn)({
  background: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  color: theme.palette.primary.light,
  img: {
    width: "20px",
    marginLeft: "1rem",
  },
  ":hover": {
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
    img: {
      filter: "invert(1)",
    },
  },
});
// PrimaryBtn end

// SecondaryBtn start
export const SecondaryBtn = styled(StyledBtn)({
  borderColor: theme.palette.primary.light,
  color: theme.palette.primary.light,
  img: {
    width: "20px",
    marginLeft: "1rem",
  },
  ":hover": {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    img: {
      filter: "invert(1)",
    },
  },
});
// SecondaryBtn end

// ---------------------------------

// header start
export const NavigationBar = styled(AppBar)({
  background: theme.palette.primary.dark,
  padding: "0.5rem 0",
  boxShadow: "none",
  "@media (max-width:800px)": {
    position: "fixed",
  },
  ".MuiToolbar-root": {
    display: "flex",
    justifyContent: "space-between",
    padding: 0,
  },
  ".nav": {
    // borderRadius: "0.5rem",
    overflow: "hidden",
    a: {
      textDecoration: "none",
      padding: "0.5rem 2rem",
      display: "inline-block",
      color: theme.palette.primary.main,
      fontWeight: "700",
      borderBottom: "2px solid #000",
      transition: "0.5s all",
      ":hover": {
        // background: theme.palette.secondary.main,
        borderBottom: "2px solid #01a1db",
      },
    },
  },
});
export const SubMenu = styled(Menu)({
  ".MuiList-root": {
    background: theme.palette.secondary.main,
    padding: 0,
    a: {
      color: theme.palette.primary.light,
      textDecoration: "none",
    },
    ".MuiButtonBase-root:hover": {
      background: theme.palette.primary.main,
    },
  },
});
export const MobileDrawer = styled(Drawer)({
  ".mobileDrawer": {
    width: "250px",
    padding: 0,
    height: "100vh",
    background: theme.palette.primary.main,
    position: "fixed",
    a: {
      color: theme.palette.primary.light,
      textDecoration: "none",
      display: "flex",
      justifyContent: "space-between",
    },
  },
});
export const MobileDropDown = styled(Menu)({
  ".MuiList-root": {
    background: theme.palette.secondary.main,
    padding: 0,
    a: {
      color: theme.palette.primary.light,
      textDecoration: "none",
    },
    ".MuiButtonBase-root:hover": {
      background: theme.palette.primary.light,
      a: {
        color: theme.palette.primary.main,
      },
    },
  },
});
// header end

// ---------------------------------

// bannerSec start
export const BannerBox = styled(Box)({
  background: `url(${BannerBg})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  position: "relative",
  marginBottom: "5rem",
  zIndex: 1,
  ":after": {
    position: "absolute",
    content: "''",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    background: theme.palette.primary.dark,
    opacity: "0.5",
    zIndex: "-1",
  },
});
export const BannerGrid = styled(Grid)({
  display: "flex",
  alignItems: "center",
  height: "100vh",
  "@media (max-width:1200px)": {
    // display: "block",
    height: "auto",
    padding: "5rem 0",
    // marginBottom: "3rem",
  },
});
export const BanSlider = styled(Box)({
  ".slick-dots": {
    bottom: "-5rem",
    textAlign: "left",
    "@media (max-width:600px)": {
      bottom: "-0.5rem",
    },
    li: {
      width: "20px",
      height: "5px",
      background: theme.palette.primary.light,
      opacity: "0.8",
      borderRadius: "5px",
      "&.slick-active": {
        background: theme.palette.primary.main,
        width: "30px",
      },
      button: {
        ":before": {
          opacity: 0,
          top: "-6px",
        },
      },
    },
  },
});
export const BannerCntnt = styled(Box)({
  "@media (max-width:600px)": {
    marginBottom: "1.5rem",
    paddingBottom: "1.5rem",
    paddingTop: "4rem",
  },
  ".MuiTypography-h6": {
    fontWeight: 700,
    fontSize: "22px",
    marginBottom: "1rem",
    color: theme.palette.secondary.main,
    "@media (min-width:600px)": {
      fontSize: "18px",
    },
  },
  ".MuiTypography-h2": {
    fontWeight: 700,
    fontSize: "50px",
    marginBottom: "1rem",
    color: theme.palette.primary.main,
    "@media (max-width:1200px)": {
      fontSize: "30px",
    },
  },
  ".MuiTypography-body1": {
    fontSize: "19px",
    marginBottom: "2rem",
    lineHeight: "30px",
    color: theme.palette.primary.light,
    fontWeight: "500",
  },
});
// bannerSec end

// ---------------------------------

// aboutSec start
export const AboutGrid = styled(Grid)({
  display: "flex",
  alignItems: "center",
  ".aboutImg": {
    img: {
      width: "95%",
      "@media (max-width:1200px)": {
        width: "90%",
      },
      "@media (max-width:800px)": {
        width: "100%",
      },
    },
  },
});
export const AboutSecBox = styled(Box)({
  ".MuiTypography-h6": {
    fontWeight: 700,
    fontSize: "22px",
    marginBottom: "1rem",
    textTransform: "uppercase",
    color: theme.palette.secondary.main,
  },
  ".MuiTypography-h2": {
    fontWeight: 700,
    fontSize: "44px",
    marginBottom: "1rem",
    color: theme.palette.primary.main,
    "@media (max-width:1092px)": {
      fontSize: "35px",
    },
    "@media (max-width:600px)": {
      fontSize: "30px",
    },
    ".MuiBox-root": {
      color: theme.palette.secondary.main,
    },
  },
  ".MuiTypography-body1": {
    fontSize: "18px",
    marginBottom: "2rem",
    lineHeight: "30px",
    textAlign: "justify",
  },
});
// aboutSec end

// ---------------------------------

// serviceSec start
export const ServiceSec = styled(Box)({
  margin: "5rem 0",
  // ".MuiContainer-root": {
  //   "@media ((min-width: 1200px))": {
  //     maxWidth: "1320px",
  //   },
  // },
});
export const ServiceSecHead = styled(Box)({
  ".MuiTypography-h6": {
    fontWeight: 700,
    fontSize: "22px",
    marginBottom: "1rem",
    textTransform: "uppercase",
    color: theme.palette.secondary.main,
  },
  ".MuiTypography-h2": {
    fontWeight: 700,
    fontSize: "44px",
    marginBottom: "1rem",
    color: theme.palette.primary.main,
    ".MuiBox-root": {
      color: theme.palette.secondary.main,
    },
    "@media (max-width:1092px)": {
      fontSize: "35px",
    },
    "@media (max-width:600px)": {
      fontSize: "30px",
    },
  },
  ".MuiTypography-body1": {
    fontSize: "18px",
    marginBottom: "2rem",
    lineHeight: "30px",
  },
});
export const ServiceBox = styled(Box)({
  ".MuiBox-root": {
    marginBottom: "1rem",
    img: {
      width: "80px",
      background: theme.palette.primary.main,
      borderRadius: "5px",
      padding: "0.5rem",
    },
  },
  padding: "1.5rem",
  position: "relative",
  boxShadow: "0px 0px 20px -10px rgba(0,0,0,0.75)",
  borderRadius: "15px",
  overflow: "hidden",
  minHeight: "320px",
  margin: "0 0.5rem 1rem",
  transition: "0.5s all",
  background: theme.palette.primary.light,
  "@media (max-width:1092px)": {
    minHeight: "350px",
  },
  ":hover": {
    background: theme.palette.primary.gradient,
    transition: "0.5s all",
    ".serviceName,.serviceDet,.serviceId": {
      color: theme.palette.primary.light,
    },
  },
  ".serviceId": {
    fontSize: "70px",
    fontWeight: "700",
    position: "absolute",
    top: 0,
    right: "0.5rem",
    opacity: "0.1",
    lineHeight: "normal",
    color: theme.palette.primary.main,
  },
  ".serviceName": {
    fontSize: "24px",
    fontWeight: "700",
    whiteSpace: "break-spaces",
    lineHeight: "28px",
    color: theme.palette.primary.main,
  },
  ".serviceDet": {
    fontSize: "16px",
    fontWeight: "400",
    textAlign: "justify",
  },
  ".serviceLink": {
    width: "35px",
    position: "absolute",
    bottom: 0,
    right: 0,
    background: theme.palette.primary.main,
    padding: "0.5rem",
    borderRadius: "5px 0 0 0",
  },
});
// serviceSec end

// ---------------------------------

// QuoteSec start
export const QuoteBox = styled(Box)({
  background: theme.palette.primary.gradient,
  padding: "3rem",
  borderRadius: "30px",
  "@media (max-width:600px)": {
    padding: "1.5rem",
  },
  ".MuiGrid-root": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width:1092px)": {
      justifyContent: "center",
    },
    ".MuiTypography-h3": {
      color: theme.palette.primary.light,
      fontSize: "40px",
      lineHeight: "50px",
      "@media (max-width:1092px)": {
        fontSize: "32px",
        lineHeight: "42px",
        textAlign: "center",
        marginBottom: "1rem",
      },
      "@media (max-width:600px)": {
        fontSize: "26px",
        lineHeight: "35px",
        textAlign: "center",
        marginBottom: "1rem",
      },
      ".MuiButtonBase-root": {
        "@media (max-width:1200px)": {
          margin: "0 auto",
          display: "table",
        },
      },
    },
  },
});
// QuoteSec end

// ---------------------------------

// testimonialSec start
export const TestimonialBox = styled(Box)({
  margin: "5rem 0",
  ".slick-current .MuiBox-root": {
    background: theme.palette.primary.gradient,
    color: theme.palette.primary.light,
    ".MuiTypography-body1,.MuiTypography-h6": {
      color: theme.palette.primary.light,
    },
  },
  ".slick-dots": {
    bottom: "-5rem",
    li: {
      width: "20px",
      height: "5px",
      background: "#ccc",
      borderRadius: "5px",
      "&.slick-active": {
        background: theme.palette.primary.main,
        width: "30px",
      },
      button: {
        ":before": {
          opacity: 0,
          top: "-6px",
        },
      },
    },
  },
  ".slick-arrow": {
    "&.slick-prev": {
      left: "-50px",
    },
    ":before": {
      fontSize: "50px",
      color: theme.palette.primary.main,
      opacity: 1,
      ":hover": {
        background: theme.palette.primary.dark,
      },
    },
  },
});
export const TestimonialHead = styled(Box)({
  textAlign: "center",
  marginBottom: "3rem",
  ".MuiTypography-h6": {
    fontWeight: 700,
    fontSize: "22px",
    marginBottom: "0.5rem",
    textTransform: "uppercase",
  },
  ".MuiTypography-h2": {
    fontWeight: 700,
    fontSize: "44px",
    color: theme.palette.primary.main,
    "@media (max-width:1092px)": {
      fontSize: "35px",
    },
    "@media (max-width:600px)": {
      fontSize: "30px",
    },
  },
});
export const ReviewBox = styled(Box)({
  background: theme.palette.primary.light,
  padding: "1.5rem",
  borderRadius: "10px",
  margin: "0 0.5rem",
  ".MuiTypography-body1": {
    color: theme.palette.primary.dark,
    marginBottom: "0.5rem",
  },
  ".MuiTypography-h6": {
    color: theme.palette.primary.dark,
    fontWeight: "700",
  },
});
// testimonialSec end

// ---------------------------------

// faqSec start
export const FaqBox = styled(Box)({
  margin: "5rem 0 0 0",
  padding: "8rem 0",
  background: theme.palette.primary.gradient,
  backgroundSize: "cover",
  position: "relative",
  "@media (max-width:800px)": {
    padding: "4rem 0",
  },
  ":after": {
    position: "absolute",
    top: 0,
    right: 0,
    width: "45%",
    height: "100%",
    content: "''",
    background: `url(${FaqBG})`,
    backgroundSize: "cover",
    "@media (max-width:900px)": {
      display: "none",
    },
  },
});
export const FaqBoxHead = styled(Box)({
  marginBottom: "2rem",
  ".MuiTypography-h6": {
    fontWeight: 700,
    fontSize: "22px",
    marginBottom: "0.5rem",
    textTransform: "uppercase",
    color: theme.palette.primary.light,
  },
  ".MuiTypography-h2": {
    fontWeight: 700,
    fontSize: "44px",
    color: theme.palette.primary.light,
    span: {
      color: theme.palette.primary.dark,
    },
    "@media (max-width:1092px)": {
      fontSize: "35px",
    },
    "@media (max-width:600px)": {
      fontSize: "30px",
    },
  },
});
export const Faq = styled(Box)({
  ".MuiPaper-root,.Mui-expanded": {
    marginBottom: "1rem !important",
    overflow: "hidden",
    ".MuiAccordionSummary-content p": {
      fontWeight: 700,
      fontSize: "18px",
      color: theme.palette.primary.main,
    },
  },
  ".Mui-expanded .MuiButtonBase-root": {
    background: theme.palette.primary.dark,
    ".MuiAccordionSummary-content p": {
      color: theme.palette.primary.light,
    },
    ".MuiSvgIcon-root": {
      filter: "invert(1)",
    },
  },
});
// faqSec end

// ---------------------------------

// projectCounterSec start
export const ProjectCounterBox = styled(Box)({
  background: theme.palette.primary.dark,
  padding: "3rem",
  position: "relative",
  marginTop: "-50px",
  textAlign: "center",
  "@media (max-width:600px)": {
    marginTop: "5rem",
  },
  ".MuiGrid-root": {
    span: {
      fontSize: "50px",
      fontWeight: 700,
      color: theme.palette.primary.light,
      "@media (max-width:1092px)": {
        fontSize: "35px",
      },
      "@media (max-width:600px)": {
        fontSize: "30px",
      },
    },
    ".MuiTypography-root": {
      fontSize: "20px",
      color: theme.palette.primary.light,
      "@media (max-width:1092px)": {
        fontSize: "18px",
      },
    },
  },
});
// projectCounterSec end

// ---------------------------------

// ContactFormBox start
export const ContactSec = styled(Box)({
  backgroundSize: "cover",
  position: "relative",
  ":after": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "43%",
    height: "100%",
    content: "''",
    background: `url(${ContactBG})`,
    backgroundSize: "cover",
    "@media (max-width:900px)": {
      display: "none",
    },
  },
});
export const ContactBox = styled(Box)({
  boxShadow: "0px 0px 20px -10px rgba(0,0,0,0.75)",
  padding: "2rem",
  "@media (max-width:600px)": {
    padding: "1rem",
  },
  background: theme.palette.primary.light,
  ".MuiTypography-h6": {
    fontWeight: 700,
    fontSize: "22px",
    marginBottom: "0.5rem",
    textTransform: "uppercase",
    color: theme.palette.secondary.main,
  },
  ".MuiTypography-h2": {
    fontSize: "40px",
    fontWeight: "700",
    color: theme.palette.primary.main,
    marginBottom: "1rem",
    padding: "0 0.5rem",
    "@media (max-width:600px)": {
      fontSize: "30px",
    },
  },
  ".MuiTypography-body1": {
    fontSize: "18px",
    marginBottom: "2rem",
    lineHeight: "30px",
  },
});
export const ContactFormBox = styled(Box)({
  ".MuiGrid-root": {
    "@media (max-width:600px)": {
      display: "block",
    },
  },
  ".MuiGrid-grid-lg-6,.MuiGrid-grid-lg-12": {
    padding: "0 0.5rem",
    "@media (max-width:600px)": {
      padding: "0",
    },
    ".MuiFormControl-root": {
      width: "100%",
      marginBottom: "0.5rem",
    },
  },
});
// ContactFormBox end

// ---------------------------------

// footer start
export const FooterBox = styled(Box)({
  background: theme.palette.primary.gradient,
  color: theme.palette.primary.light,
  padding: "5rem 0 3rem",
  ".MuiGrid-grid-lg-4,.MuiGrid-grid-lg-3,.MuiGrid-grid-lg-2": {
    padding: "0 0.5rem",
  },
  ".footerHeading": {
    fontSize: "20px",
    fontWeight: "700",
  },
  ".MuiListItem-root": {
    padding: "0",
    marginBottom: "0.5rem",
    a: {
      textDecoration: "none",
      fontSize: "16px",
      color: theme.palette.primary.light,
    },
    ".MuiTypography-root": {
      color: theme.palette.primary.light,
    },
  },
  ".MuiListItemIcon-root": {
    minWidth: "35px",
    img: {
      width: "25px",
    },
  },
});
// footer end

// ---------------------------------

// BottomRowBox start
export const BottomRowBox = styled(Box)({
  padding: "0.8rem 0",
  background: theme.palette.primary.dark,
  ".MuiTypography-root": {
    color: theme.palette.primary.light,
    textAlign: "center",
  },
  ".MuiList-root": {
    display: "flex",
    padding: 0,
    ".MuiListItem-root": {
      padding: 0,
      position: "relative",
      textAlign: "center",
      justifyContent: "center",
      ":after": {
        position: "absolute",
        content: "''",
        top: 0,
        right: "-10px",
        width: "1px",
        height: "100%",
        background: theme.palette.primary.light,
      },
      ":nth-of-type(2):after": {
        display: "none",
      },
      a: {
        textDecoration: "none",
        color: theme.palette.primary.light,
      },
    },
  },
});
// BottomRowBox end

// ---------------------------------

// SocialBox start
export const SocialBox = styled(Box)({
  ".MuiList-root": {
    padding: 0,
    display: "inline-flex",
  },
  ".MuiListItem-root": {
    padding: 0,
    background: theme.palette.primary.light,
    borderRadius: "5px",
    width: "35px",
    height: "35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "0.5s all",
    img: {
      width: "20px",
      position: "relative",
      top: "2px",
      transition: "0.5s all",
    },
    ":hover": {
      background: theme.palette.primary.dark,
      img: {
        filter: "invert(1)",
      },
    },
  },
});
// SocialBox end

// ---------------------------------

// servicePg start
export const ServicePgHead = styled(Box)({
  padding: "5rem 0 0",
  marginBottom: "2rem",
  ".MuiTypography-h6": {
    fontWeight: 700,
    fontSize: "22px",
    marginBottom: "0.5rem",
    textTransform: "uppercase",
    color: theme.palette.secondary.main,
  },
  ".MuiTypography-h2": {
    fontWeight: 700,
    fontSize: "44px",
    color: theme.palette.primary.main,
    marginBottom: "1rem",
    span: {
      color: theme.palette.secondary.main,
    },
    "@media (max-width:1092px)": {
      fontSize: "35px",
    },
    "@media (max-width:600px)": {
      fontSize: "30px",
    },
  },
  ".MuiTypography-body1": {
    fontSize: "18px",
    marginBottom: "2rem",
    lineHeight: "30px",
  },
});
export const ServicePgGrid = styled(Grid)({
  display: "flex",
  alignItems: "center",
});
export const ServicePgBox = styled(Box)({
  marginBottom: "5rem",
  ".MuiTypography-h2": {
    fontWeight: 700,
    fontSize: "44px",
    color: theme.palette.primary.main,
    marginBottom: "1rem",
    "@media (max-width:1092px)": {
      fontSize: "35px",
    },
    "@media (max-width:600px)": {
      fontSize: "30px",
    },
  },
  ".MuiTypography-body1": {
    fontSize: "18px",
    marginBottom: "2rem",
    lineHeight: "30px",
    textAlign: "justify",
  },
});
export const ServicePgImg = styled(Box)({
  img: {
    width: "100%",
    height: "400px",
    objectFit: "contain",
  },
  ":nth-child(odd)": {
    marginRight: "2rem",
    "@media (max-width:600px)": {
      marginRight: "0",
      marginBottom: "1rem",
    },
  },
  ":nth-cild(even)": {
    marginLeft: "2rem",
    marginRight: "0 !important",
  },
});
// servicePg end

// ---------------------------------

// contactPg start
export const ContactPgDetail = styled(Box)({
  ".MuiTypography-h6": {
    fontWeight: 700,
    fontSize: "22px",
    marginBottom: "0.5rem",
    textTransform: "uppercase",
    color: theme.palette.secondary.main,
    "@media (max-width:800px)": {
      fontSize: "20px",
    },
  },
  ".MuiTypography-h2": {
    fontWeight: 700,
    fontSize: "44px",
    color: theme.palette.primary.main,
    marginBottom: "1rem",
    span: {
      color: theme.palette.secondary.main,
    },
    "@media (max-width:1092px)": {
      fontSize: "35px",
    },
    "@media (max-width:600px)": {
      fontSize: "30px",
    },
  },
  ".MuiTypography-body1": {
    fontSize: "18px",
    marginBottom: "2rem",
    lineHeight: "30px",
    textAlign: "justify",
    paddingRight: "2rem",
  },
  ".MuiListItem-root": {
    padding: 0,
    marginBottom: "1.5rem",
    ".MuiListItemIcon-root": {
      background: theme.palette.primary.main,
      borderRadius: "50%",
      padding: "1rem",
      marginRight: "1rem",
      img: {
        width: "40px",
      },
    },
    ".MuiTypography-root": {
      marginBottom: "0",
      fontSize: "17px",
      a: {
        textDecoration: "none",
        color: theme.palette.primary.dark,
      },
    },
  },

  ".MuiListItemIcon-root img": {},
});
// contactPg start

// ---------------------------------

// aboutPg start
export const AboutPgGrid = styled(Grid)({
  display: "flex",
  alignItems: "center",
  "@media (max-width:1200px)": {
    justifyContent: "center",
  },
  ".aboutImg": {
    img: {
      width: "95%",
      "@media (max-width:1200px)": {
        width: "90%",
        margin: "0 auto",
        display: "table",
      },
      "@media (max-width:800px)": {
        width: "100%",
      },
    },
  },
});
export const AboutPgBox = styled(Box)({
  "@media (max-width:600px)": {
    fontSize: "30px",
  },
  ".MuiTypography-h6": {
    fontWeight: 700,
    fontSize: "22px",
    marginBottom: "0.5rem",
    textTransform: "uppercase",
    color: theme.palette.secondary.main,
  },
  ".MuiTypography-h2": {
    fontWeight: 700,
    fontSize: "44px",
    color: theme.palette.primary.main,
    marginBottom: "1rem",
    span: {
      color: theme.palette.secondary.main,
    },
    "@media (max-width:1092px)": {
      fontSize: "35px",
    },
    "@media (max-width:600px)": {
      fontSize: "30px",
    },
  },
  ".MuiTypography-body1": {
    fontSize: "18px",
    marginBottom: "2rem",
    lineHeight: "30px",
    textAlign: "justify",
  },
});
export const CustomTabs = styled(Tabs)({
  ".MuiButtonBase-root": {
    fontSize: "18px",
    fontWeight: 700,
    "@media (max-width:600px)": {
      fontSize: "16px",
    },
  },
});
export const WrokingHead = styled(Box)({
  marginBottom: "2rem",
  ".MuiTypography-h2": {
    fontWeight: 700,
    fontSize: "44px",
    color: theme.palette.primary.main,
    marginBottom: "1rem",
    textAlign: "center",
    span: {
      color: theme.palette.secondary.main,
    },
    "@media (max-width:1092px)": {
      fontSize: "35px",
    },
    "@media (max-width:600px)": {
      fontSize: "30px",
    },
  },
});
export const WorkingBox = styled(Box)({
  background: theme.palette.primary.light,
  padding: "1.5rem",
  borderRadius: "10px",
  position: "relative",
  margin: "0 0.5rem",
  minHeight: "270px",
  "@media (max-width:1100px)": {
    marginBottom: "1rem",
    minHeight: "320px",
  },
  "@media (max-width:900px)": {
    marginBottom: "1rem",
    minHeight: "200px",
  },
  ".MuiTypography-h2": {
    fontWeight: 700,
    position: "absolute",
    top: "0.5rem",
    right: "1.5rem",
    opacity: "0.2",
  },
  ".MuiTypography-h6": {
    fontWeight: 700,
    fontSize: "22px",
    marginBottom: "1rem",
    color: theme.palette.primary.main,
    textAlign: "justify",
  },
  ".MuiTypography-body1": {
    textAlign: "justify",
  },
});
// aboutPg end
