const HomeSliderData = [
  {
    servicBannerSubtitle: "Design Excellence Firm",
    seviceBannerName: "Expand your design team swiftly, in a matter of weeks.",
    serviceBannerDetail:
      "We are dedicated to propelling your business to new heights through our innovative and thought-provoking design concepts.",
  },
  {
    servicBannerSubtitle: "Visual Stories Crafted Just for You",
    seviceBannerName: "Graphic Designing",
    serviceBannerDetail:
      "Transforming Ideas into Striking Visuals: Expert Graphic Design Tailored to Your Unique Vision",
  },
  {
    servicBannerSubtitle: "Crafting Elevated Online Experiences.",
    seviceBannerName: "Website Development",
    serviceBannerDetail:
      "Crafting Seamless Online Experiences: Elevate Your Brand with Expert Website Development Services",
  },
  {
    servicBannerSubtitle: "Boosting Visibility, Driving Traffic",
    seviceBannerName: "Search Engine Optimization",
    serviceBannerDetail:
      "Optimizing Visibility, Elevating Rankings: Unlock Your Website's Full Potential with Proven SEO Strategies",
  },
  {
    servicBannerSubtitle: "Maximize Impact, Minimize Effort",
    seviceBannerName: "Paid Marketing",
    serviceBannerDetail:
      "Strategic Campaigns, Tangible Results: Propel Your Business Forward with Targeted Paid Marketing Solutions",
  },
];

export default HomeSliderData;
