import { Grid, Typography } from "@mui/material";
// mport { List, ListItem,  } from "@mui/material";
import React from "react";
// import { Link } from "react-router-dom";

const BottomRowComponent = () => {
  return (
    <>
      <Grid container>
        <Grid lg={4} mx="auto">
          <Typography>Copyright © 2024 RAVIAN SERVICES LTD.</Typography>
        </Grid>
        {/* <Grid lg={3}>
          <List>
            <ListItem>
              <Link to="/">Terms & Condition</Link>
            </ListItem>
            <ListItem>
              <Link to="/">Privacy Policy</Link>
            </ListItem>
          </List>
        </Grid> */}
      </Grid>
    </>
  );
};

export default BottomRowComponent;
