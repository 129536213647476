import React from "react";
import { Link } from "react-router-dom";
import { Container, Grid, Typography } from "@mui/material";
import { QuoteBox, SecondaryBtn } from "../../style/Theme-Style";
import ArrowRight from "../../assets/images/arrow-right.svg";

const FreeQouteComponent = () => {
  return (
    <>
      <Container>
        <QuoteBox>
          <Grid container>
            <Grid lg={7} md={12} sm={12}>
              <Typography variant="h3" sx={{ fontWeight: "700" }}>
                Collaborate with teams lauded by CTOs for being "incredibly
                friendly" and "dependable."
              </Typography>
            </Grid>
            <Grid lg={3} md={12} sm={12}>
              <Link to="/contact">
                <SecondaryBtn>
                  Get a Free Quote <img src={ArrowRight} alt="Right Arrow" />
                </SecondaryBtn>
              </Link>
            </Grid>
          </Grid>
        </QuoteBox>
      </Container>
    </>
  );
};

export default FreeQouteComponent;
