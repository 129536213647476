import React from "react";
import { ServiceBox } from "../../style/Theme-Style";
import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ChainIcon from "../../assets/images/chain.svg";

const ServiceBoxComponent = (curElem) => {
  const { id, imgSrc, serviceName, ServiceDet } = curElem;
  return (
    <>
      <Grid lg={6} md={6} sm={12} xs={12}>
        <ServiceBox>
          <Box>
            <img src={imgSrc} alt="" />
          </Box>
          <Typography className="serviceId">{id}</Typography>
          <Typography className="serviceName" sx={{ mb: 2 }}>
            {serviceName}
          </Typography>
          <Typography className="serviceDet">{ServiceDet}</Typography>
          <Link to="/service">
            <img src={ChainIcon} alt="Service Link" className="serviceLink" />
          </Link>
        </ServiceBox>
      </Grid>
    </>
  );
};

export default ServiceBoxComponent;
