const FaqData = [
  {
    question: "How to get in touch with us?",
    answer:
      "E-mail us at info@ravianservices.com and we will get back to you shortly.",
    open: true,
  },
  {
    question: "How much experience do we have?",
    answer:
      "We have a 2 years of experience in developing websites, mobile applications and digital marketing.",
    open: false,
  },
  {
    question: "Why choose us? ",
    answer:
      "Our dedicated team, core values and long-term customer relations are what make us stand out.",
    open: false,
  },
];

export default FaqData;
