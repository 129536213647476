import React, { useState } from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
} from "@material-ui/core";
import Hamburger from "../../assets/images/hamburger.svg";
import { Link } from "react-router-dom";
import { MobileDrawer, MobileDropDown, theme } from "../../style/Theme-Style";
import AngleDown from "../../assets/images/angle-down.svg";

function DrawerComponent() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MobileDrawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List className="mobileDrawer">
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/">Home</Link>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/about">About</Link>
            </ListItemText>
          </ListItem>
          <ListItem onClick={handleMenuOpen}>
            <ListItemText style={{ margin: 0 }}>
              <Link
                to=""
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                Services
                {/* <Box
                  component="img"
                  src={DropDown}
                  sx={{ width: "25px" }}
                ></Box> */}
                <img
                  src={AngleDown}
                  alt=""
                  style={{ width: "24px", height: "24px" }}
                />
              </Link>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/contact">Contact</Link>
            </ListItemText>
          </ListItem>
        </List>
      </MobileDrawer>
      <MobileDropDown
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose}>
          <Link to="/service#graphicDesigning">Graphic Designing</Link>
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <Link to="/service#webDevelopment">Web Development</Link>
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <Link to="/service#seo">Search Engine Optimization</Link>
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <Link to="/service#paidMarketing">Paid Marketing</Link>
        </MenuItem>
      </MobileDropDown>
      <IconButton
        style={{ background: theme.palette.primary.main }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <img src={Hamburger} alt="" style={{ width: "30px" }} />
      </IconButton>
    </>
  );
}

export default DrawerComponent;
