import { List, ListItem } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import FacebookIcon from "../../assets/images/facebook.svg";
import InstagramIcon from "../../assets/images/instagram.svg";
import LinkedinIcon from "../../assets/images/linkedin.svg";
import { SocialBox } from "../../style/Theme-Style";

const SocialIconsComponent = () => {
  return (
    <>
      <SocialBox>
        <List sx={{ display: "flex" }}>
          {/* <ListItem>
            <Link to="/">
              <img src={FacebookIcon} alt="" />
            </Link>
          </ListItem> */}
          <ListItem sx={{ marginX: 1 }}>
            <Link to="https://www.instagram.com/ravianservices/?igsh=em5ydW5wMTRiaWZi">
              <img src={InstagramIcon} alt="" />
            </Link>
          </ListItem>
          <ListItem>
            <Link to="https://www.linkedin.com/company/ravianservices/">
              <img src={LinkedinIcon} alt="" />
            </Link>
          </ListItem>
        </List>
      </SocialBox>
    </>
  );
};

export default SocialIconsComponent;
