import * as React from "react";
import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CustomTabs } from "../../style/Theme-Style";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <CustomTabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Our Mission" {...a11yProps(0)} />
          <Tab label="Our Vision" {...a11yProps(1)} />
          <Tab label="Our Value" {...a11yProps(2)} />
        </CustomTabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        "Our mission is to seamlessly blend creativity with functionality,
        providing our clients with unparalleled design solutions that leave a
        lasting impression. At the forefront of design excellence, we are
        committed to transforming visions into captivating realities, pushing
        the boundaries of aesthetic innovation in every project we undertake."
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        "Our vision encompasses a dynamic landscape where cutting-edge
        technology, stringent security measures, and adherence to industry
        regulations converge to create a design ecosystem that not only meets
        but exceeds the expectations of our clients. We are dedicated to shaping
        a future where design excellence is synonymous with uncompromising
        security and regulatory adherence."
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        "We strive to understand the core drivers of your business, ensuring
        that our creative endeavors align seamlessly with your goals. Our
        commitment extends beyond visuals; it encompasses strategic guidance
        tailored to your unique needs. We believe in empowering you with
        technology solutions that not only enhance your design experience but
        also contribute to your business success. With a focus on your best
        interests, we offer collaborative budgeting assistance, ensuring that
        every design investment delivers measurable value and drives your vision
        forward."
      </CustomTabPanel>
    </Box>
  );
}
